<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a routerLink="/" class="logo" (click)="launch({ id: 'top' })">
      <img src="/assets/rudikral-logo-letter.svg" />
    </a>
    <a
      role="button"
      class="navbar-burger burger"
      aria-label="menu"
      aria-expanded="false"
      [class.is-active]="isOpen"
      (click)="toggle()"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div #items class="navbar-menu" [class.is-active]="isOpen">
    <div class="navbar-start">
      <a
        *ngFor="let nav of navigation$ | async"
        routerLink="/"
        class="navbar-item"
        (click)="launch(nav)"
      >
        {{ nav.label }}
      </a>
    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <!-- <app-social></app-social> -->
        </div>
      </div>
    </div>
  </div>
</nav>
