import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselModule } from './carousel/carousel.module';
import { ProjectComponent } from './project.component';

@NgModule({
  declarations: [ProjectComponent],
  imports: [CommonModule, CarouselModule],
  exports: [ProjectComponent],
})
export class ProjectModule {}
