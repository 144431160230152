import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactFormModule } from '../contact-form/contact-form.module';
import { ContactModule } from '../contact/contact.module';
import { SocialModule } from '../social/social.module';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    SocialModule,
    ContactModule,
    ContactFormModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
