<!-- <div> -->
<img class="hero-image" src="/assets/impressions/project-leibomen.jpg" />
<!-- <div
    class="hero-image"
    style="background-image: url('/assets/impressions/project-leibomen.jpg');"
  ></div> -->

<div class="hero">
  <app-logo></app-logo>
  <!-- <div class="logo">
    <img src="/assets/rudikral-logo.svg" />
  </div> -->
  <div class="fond content">
    <!-- <h1 class="is-primary is-size-1 has-text-white">Project Xyz</h1> -->
    <p class="is-size-4">
      <span class="intro-1">
        Uw tuin is een belangrijk onderdeel van uw huis en verdient daarom de
        juiste aandacht. Een goed ingerichte tuin geeft rust en een gevoel van
        vrijheid, dat is echt genieten!
      </span>
      <span class="intro-2">
        Rudi Kral heeft passie voor het buitenleven en helpt u graag om de tuin
        mooi in te richten. Neem voor meer informatie
        <a (click)="launchContact()">contact met ons op</a>. Wij staan u graag
        te woord!
      </span>
    </p>
    <!-- <p class="is-size-4 intro-2">
      
    </p> -->
    <button class="arrow to-header" (click)="scroll()">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <path
            d="M455.543,301.781c-4.16-4.16-10.923-4.16-15.083,0L266.657,475.584V10.667C266.657,4.779,261.879,0,255.991,0
              c-5.888,0-10.667,4.779-10.667,10.667v464.917L71.543,301.803c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083
              l191.979,191.979c0.981,0.981,2.176,1.771,3.477,2.325c1.301,0.533,2.688,0.811,4.075,0.811c1.387,0,2.773-0.277,4.075-0.811
              c1.323-0.533,2.496-1.323,3.477-2.325l191.979-191.979C459.703,312.704,459.703,305.963,455.543,301.781z"
          />
        </g>
      </svg>
    </button>
  </div>
</div>
<!-- </div> -->
