import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  elements: Map<number, HTMLElement> = new Map();
  register(id: any, element: ElementRef): void {
    this.elements.set(id, element.nativeElement);
    this.elements[id] = element.nativeElement;
  }

  launch(element: any | HTMLElement, options?: ScrollIntoViewOptions): void {
    if (!(element instanceof HTMLElement)) {
      element = this.elements.get(element);
    }
    if (element instanceof HTMLElement) {
      (element as HTMLElement).scrollIntoView(
        options ||
          ({ block: 'start', inline: 'start' } as ScrollIntoViewOptions)
      );
    }
  }
}
