<div class="content" id="contact">
  <app-contact></app-contact>
  <app-contact-form></app-contact-form>
  <app-social></app-social>
</div>

<div class="">
  <!-- <button class="arrow to-top" (click)="scrollToTop()">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <path
          d="M455.543,301.781c-4.16-4.16-10.923-4.16-15.083,0L266.657,475.584V10.667C266.657,4.779,261.879,0,255.991,0
        c-5.888,0-10.667,4.779-10.667,10.667v464.917L71.543,301.803c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083
        l191.979,191.979c0.981,0.981,2.176,1.771,3.477,2.325c1.301,0.533,2.688,0.811,4.075,0.811c1.387,0,2.773-0.277,4.075-0.811
        c1.323-0.533,2.496-1.323,3.477-2.325l191.979-191.979C459.703,312.704,459.703,305.963,455.543,301.781z"
        />
      </g>
    </svg> 
  </button>-->

  <div class="legal">
    <span class="copyright">&copy; Rudi Kral {{ year }}</span>
    <a routerLink="/content/53/algemene-voorwaarden" (click)="launch()"
      >Algemene voorwaarden</a
    >
    <!-- <a>Privacy</a>
    <a>Cookies</a> -->
  </div>
</div>
