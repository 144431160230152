import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Category } from '../project/category';

@Injectable({
  providedIn: 'root',
})
export class WordpressCategoryLoaderService {
  private wpBaseUrl = `${environment.wpBaseUrl}/wp-json/wp/v2/`;

  constructor(private http: HttpClient) {}

  private categories: Observable<any>;

  get categories$() {
    if (!this.categories) {
      const url = `${this.wpBaseUrl}categories?_fields=id,parent,name,description&orderby=id`;
      this.categories = this.http
        .get(url)
        .pipe(
          startWith(null),
          filter(Boolean),
          map(this.normalizeRootCategories.bind(this))
        );
    }
    return this.categories;
  }

  private normalizeRootCategories(categories: any[], parent = 0): Category[] {
    return categories
      .filter(cat => cat.parent === parent)
      .map(cat => ({
        id: cat.id,
        title: cat.name,
        description: cat.description,
        childs: this.normalizeRootCategories(categories, cat.id),
      }));
  }
}
