import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from './navbar/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('header', { static: false }) header: ElementRef;
  constructor(
    private element: ElementRef,
    private navService: NavService,
    private router: Router
  ) {}

  ngOnInit() {
    this.navService.register('top', this.element);

    // this.router.events
    //   .pipe(filter(ev => ev instanceof NavigationEnd))
    //   .subscribe(evt => {
    //     console.log('route change', this.header.nativeElement);
    //     // window.scrollTo(0, 0);
    //     if (this.header && this.header.nativeElement) {
    //       this.header.nativeElement.scrollIntoView({
    //         block: 'start',
    //       } as ScrollOptions); //{ behavior: 'smooth' }
    //     }
    //   });
  }
}
