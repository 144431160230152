import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactFormLoader {
  private wpBaseUrl = `${environment.wpBaseUrl}/wp-json/contact-form-7/v1/contact-forms/133/feedback`;

  constructor(private http: HttpClient) {}

  post(data: any): Observable<any> {
    const url = `${this.wpBaseUrl}`;
    return this.http.post(url, data);
  }
}
