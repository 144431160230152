<h3>Contactformulier</h3>
<p *ngIf="!response">
  Laat uw gegevens hieronder achter en wij nemen contact met u op.
</p>

<div
  *ngIf="response"
  class="notification"
  [class.is-primary]="response?.status === 'mail_sent'"
  [class.is-danger]="response?.status !== 'mail_sent'"
>
  <button class="delete" (click)="response = null"></button>
  {{ response.message }}
</div>

<form
  *ngIf="response?.status !== 'mail_sent'"
  [formGroup]="form"
  #myform
  (ngSubmit)="save()"
>
  <div class="field">
    <div class="control">
      <input
        class="input"
        type="text"
        formControlName="name"
        placeholder="Naam"
        autocomplete="name"
      />
    </div>
  </div>
  <div class="field">
    <div class="control">
      <input
        class="input"
        type="email"
        formControlName="email"
        placeholder="Email"
        autocomplete="email"
      />
    </div>
    <!-- <p class="help is-danger">This email is invalid</p> -->
  </div>

  <div class="field">
    <div class="control">
      <input
        class="input"
        type="tel"
        formControlName="phone"
        autocomplete="tel"
        placeholder="Telefoonnummer"
      />
    </div>
  </div>

  <div class="field">
    <!-- <label class="label">Message</label> -->
    <div class="control">
      <textarea
        class="textarea"
        placeholder="Bericht"
        formControlName="message"
      ></textarea>
    </div>
  </div>

  <div class="field is-grouped">
    <div class="control">
      <button class="button is-link" type="submit" [disabled]="form.invalid">
        Submit
      </button>
    </div>
  </div>
</form>
