import { Component, ElementRef, OnInit } from '@angular/core';
import { NavService } from '../navbar/nav.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();

  constructor(private navService: NavService, private element: ElementRef) {}

  // scrollToTop() {
  //   this.navService.launch('top');
  // }

  ngOnInit() {
    this.navService.register('contact', this.element);
  }

  launch() {
    // this.navService.launch('content', { block: 'start' });
  }
}
