import { Component, Input } from '@angular/core';
import { NavService } from '../navbar/nav.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  @Input() header: HTMLElement;

  constructor(private navService: NavService) {}

  scroll() {
    if (this.header) {
      this.header.scrollIntoView({ behavior: 'smooth' });
    }
  }

  launchContact(): void {
    this.navService.launch('contact');
  }
}
