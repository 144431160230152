import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IntersectionModule } from 'src/app/loading/intersection.module';
import { CarouselComponent } from './carousel.component';

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, IntersectionModule],
  exports: [CarouselComponent],
})
export class CarouselModule {}
