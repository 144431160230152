import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactFormComponent } from './contact-form.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [ContactFormComponent],
  exports: [ContactFormComponent],
})
export class ContactFormModule {}
