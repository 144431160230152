import { NgModule } from '@angular/core';
import {
  IntersectionDirective,
  IntersectionInDirective,
  IntersectionOutDirective,
} from './intersection.directive';

@NgModule({
  declarations: [
    IntersectionDirective,
    IntersectionInDirective,
    IntersectionOutDirective,
  ],
  exports: [
    IntersectionDirective,
    IntersectionInDirective,
    IntersectionOutDirective,
  ],
})
export class IntersectionModule {}
