import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { Category } from '../project/category';
import { WordpressCategoryLoaderService } from '../wordpress/wordpress-category-loader.service';
import { NavService } from './nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @ViewChild('items') items: ElementRef;

  navigation$ = this.categoryLoader.categories$.pipe(
    map((all: any[]) => {
      all.push({ title: 'Contact', id: 'contact' });
      return all.map(nav => ({
        id: nav.id,
        label: nav.title,
      }));
    })
  );

  constructor(
    private categoryLoader: WordpressCategoryLoaderService,
    private navService: NavService
  ) {}

  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.delay(100).then(() => {
        (this.items.nativeElement as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });
    }
  }

  private async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }

  launch(nav: Category) {
    this.navService.launch(nav.id);
    this.isOpen = false;
  }
}
