import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ContactFormLoader } from '../wordpress/wordpress-contactform-loader';

const emailOrPhoneValidator = (formGroup: FormControl) => {
  return !formGroup.value.email && !formGroup.value.phone
    ? { invalid: 'phone or email required' }
    : null;
};

const optionalEmailValidator = (control: AbstractControl) => {
  if (!control.value) {
    return null;
  }

  return Validators.email(control);
};

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit {
  form: FormGroup;
  constructor(
    private contactFormLoader: ContactFormLoader,
    private cd: ChangeDetectorRef
  ) {}

  response;

  ngOnInit() {
    this.form = new FormGroup({}, { validators: emailOrPhoneValidator });
    this.form.setControl(
      'name',
      new FormControl('', { validators: Validators.required })
    );
    this.form.setControl('phone', new FormControl(''));
    this.form.setControl(
      'email',
      new FormControl('', { validators: optionalEmailValidator })
    );
    this.form.setControl('message', new FormControl());
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    this.form.disable();

    const formData = new FormData();
    formData.append('visitor-name', this.form.value.name);
    formData.append('visitor-email', this.form.value.email);
    formData.append('visitor-phone', this.form.value.phone);
    formData.append('visitor-message', this.form.value.message || '');
    formData.append('subject', 'Contactformulier www.rudikral.nl');

    this.contactFormLoader.post(formData).subscribe(response => {
      console.log(response);
      this.response = response;
      if (response.status === 'mail_sent') {
        this.form.reset();
      }
      this.form.enable();
      this.cd.markForCheck();
    });
  }
}
